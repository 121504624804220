@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap);
@media only screen and (max-width: 900px) {
  .business-development img {
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    text-align: center !important;
  }

  footer .img-wrapper {
    border: none !important;
    text-align: center;
  }

  footer img {
    margin-bottom: 24px;
    padding: 0 !important;
  }

  .team {
    text-align: center;
  }

  .team img {
    max-width: 50%;
  }

  .we-understand h3 {
    text-align: left !important;
  }

  .business-development {
    text-align: center;
  }

  .business-development img {
    max-width: 50%;
  }

  .startups {
    text-align: center;
  }

  .startups img {
    max-width: 50%;
  }

  .startups p {
    margin-bottom: 36px;
  }

  header {
    text-align: center;
  }

  header .links {
    padding: 48px 0 0 0;
  }

  header .img-wrapper {
    border: none !important;
  }

  .how-we-do-it img {
    max-height: 24px !important;
  }

  .links,
  .links h5,
  .img-wrapper,
  .img-wrapper img {
    max-width: 300px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .about-us {
    text-align: center;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-weight: 400;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
}

p {
  line-height: 1.8em;
}

img {
  max-width: 100%;
}

nav {
  position: fixed !important;
  width: 100% !important;
  z-index: 10 !important;
}

nav.bg-dark {
  background-color: black !important;
}

nav a {
  color: black !important;
}

nav.bg-dark a {
  color: white !important;
}

/* Header */
header {
  background-color: black;
  padding: 180px 0 120px 0;
}

header .img-wrapper {
  border-right: 2px solid #1e88e5;
  text-align: right;
}

header img {
  max-width: 320px;
  padding: 48px 48px 48px 0;
}

header .links {
  color: white;
  padding: 48px 0 48px 48px;
  max-width: 400px;
}

header h5 {
  font-weight: 300;
  font-size: 28px;
  line-height: 1.3;
}

/* Footer */
footer {
  background-color: black;
  padding: 120px 0;
}

footer .img-wrapper {
  border-right: 2px solid #1e88e5;
  text-align: right;
}

footer img {
  max-width: 320px;
  padding: 48px 48px 48px 0;
}

footer .links {
  color: white;
  padding: 48px 0 48px 48px;
}

footer a {
  color: white;
  border-bottom: 1px dashed white;
}

/* Section */
section {
  padding: 96px 0;
}

section:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.03);
}

h2 {
  margin-bottom: 48px;
}

.constraint-center {
  max-width: 480px;
  margin: 0 auto;
}

.small-text {
  opacity: 0.5;
}

.business-development p,
.we-understand p {
  margin-bottom: 48px;
}

.we-understand h3 {
  text-align: right;
}

.team img {
  max-width: 50%;
  margin: 0 auto 24px 0;
  border-radius: 100%;
}

.team h5 {
  text-align: center;
}

.team .desc {
  margin-top: 24px;
  margin-bottom: 48px;
  text-align: center;
}

.team p {
  font-size: 0.8em;
}

.team .desc span {
  color: #1e88e5;
}

.about-us h5 {
  font-size: 24px;
}

.about-us p {
  margin-bottom: 48px;
}

