@media only screen and (max-width: 900px) {
  .business-development img {
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    text-align: center !important;
  }

  footer .img-wrapper {
    border: none !important;
    text-align: center;
  }

  footer img {
    margin-bottom: 24px;
    padding: 0 !important;
  }

  .team {
    text-align: center;
  }

  .team img {
    max-width: 50%;
  }

  .we-understand h3 {
    text-align: left !important;
  }

  .business-development {
    text-align: center;
  }

  .business-development img {
    max-width: 50%;
  }

  .startups {
    text-align: center;
  }

  .startups img {
    max-width: 50%;
  }

  .startups p {
    margin-bottom: 36px;
  }

  header {
    text-align: center;
  }

  header .links {
    padding: 48px 0 0 0;
  }

  header .img-wrapper {
    border: none !important;
  }

  .how-we-do-it img {
    max-height: 24px !important;
  }

  .links,
  .links h5,
  .img-wrapper,
  .img-wrapper img {
    max-width: 300px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .about-us {
    text-align: center;
  }
}
